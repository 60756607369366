<!--<i class="fas fa-3x fa-cog fa-spin"></i>-->
<!--<i class="fas fa-3x fa-cog fa-spin-reverse"></i>-->
<!--<i class="fas fa-3x fa-cog fa-spin"></i>-->
<!--<i class="fas fa-3x fa-cog fa-spin-reverse"></i>-->
<!--<i class="fas fa-3x fa-cog fa-spin"></i>-->
<svg width="100%" height="100">
  <circle fill="#666666" cx="-50" cy="50" r="2%">
    <animate id="o1" begin="0;o2.end" attributeName="cx" from="90%" to="10%" dur="3s"/>
  </circle>
  <circle fill="#666666" cx="-50" cy="50" r="2%">
    <animate id="o2" begin="o1.end" attributeName="cx" from="10%" to="90%" dur="3s"/>
  </circle>
</svg>
